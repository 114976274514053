.product-header-image {
  width: 78vw;
  aspect-ratio: 16 / 9;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
  transition: opacity 0.4s ease 0s;
  opacity: 1;
}

.header-container {
  display: flex;
  justify-content: center;
  height: 480px;
}
